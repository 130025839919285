<template>
  <section>
    <div id="live-debug" style="display: none">
      <aRow>
        <aCol :span="8">
          undoStack
          <jsonViewer
            :value="undoStack"
            :expand-depth="1"
            copyable
            boxed
            sort
          ></jsonViewer>
        </aCol>
        <aCol :span="8">
          redoStack
          <jsonViewer
            :value="redoStack"
            :expand-depth="1"
            copyable
            boxed
            sort
          ></jsonViewer>
        </aCol>
        <aCol :span="8">
          docStructure
          <jsonViewer
            :value="docStructure"
            :expand-depth="2"
            copyable
            boxed
            sort
          ></jsonViewer>
        </aCol>
      </aRow>
    </div>

    <aRow>
      <aCol class="builder-container" :span="24">
        <div
          id="print-area"
          class="builder-area"
          style="font-family: 'Poppins', sans-serif"
        >
          <div class="expandable-pages" v-if="!isRepeater">
            <div
              v-if="!previewDoc && docStructure.length > 0"
              class="collapse-all ml-5"
              @click="onClickExpandAllPages"
            >
              EXPANDIR TUDO
            </div>
            <div
              v-if="!previewDoc && docStructure.length > 0"
              class="collapse-all"
              @click="onClickCollapseAllPages"
            >
              RECOLHER TUDO
            </div>
          </div>

          <draggable v-model="docStructure" group="page" :sort="true">
            <div v-for="(page, index) in docStructure" :key="index">
              <div
                v-if="index + 1 !== 1"
                style="page-break-after: always; font-size: 0"
              >
                .
              </div>
              <div class="page">
                <div v-if="!previewDoc" class="page-header">
                  <aRow type="flex" justify="space-between">
                    <aCol class="title">
                      <span v-if="!isRepeater">
                        <a-icon class="mr-5" type="file-text" /> PAGINA
                        {{ index + 1 }}
                      </span>

                      <span v-if="isRepeater">
                        <a-icon class="mr-5" type="pic-center" />MODELO
                        REPETIDOR
                      </span>

                      <span class="dotted-phrase">
                        {{ previewPageFirstContent(page, index) }}
                      </span>
                    </aCol>

                    <aCol class="">
                      <span
                        v-if="!isRepeater"
                        class="ml-10 delete"
                        @click="clonePage(index)"
                        ><a-icon type="copy"
                      /></span>

                      <span
                        v-if="!isRepeater"
                        class="ml-10 delete"
                        @click="deletePage(index)"
                        ><a-icon type="delete"
                      /></span>

                      <span
                        class="ml-20 collapse-arrow"
                        @click="collapsePage(index)"
                        ><a-icon type="caret-down" v-if="page.collapse" />
                        <a-icon type="caret-up" v-if="!page.collapse"
                      /></span>
                    </aCol>
                  </aRow>
                </div>
                <div
                  v-if="page.collapse || previewDoc"
                  class="page-content"
                  :class="previewDoc ? 'prev-mode' : ''"
                  :span="24"
                  :style="`padding: ${page.css.padding}; font-size: ${page.css.fontSize};`"
                >
                  <aRow>
                    <draggable
                      v-model="page.sections"
                      group="sections"
                      :sort="true"
                      @end="endDragSection"
                    >
                      <aCol
                        v-for="(section, sIndex) in page.sections"
                        :key="sIndex"
                        class="sections"
                        :span="24"
                      >
                        <DocumentColumnsSection
                          :pageData="page"
                          :previewDoc="previewDoc"
                          :itemId="page.itemId"
                          :pIndex="index"
                          :sIndex="sIndex"
                          :docPages="docStructure"
                          :columns="section.columns"
                          :type="section.type"
                          :docStructure="docStructure"
                          :contract="contract"
                          :contractPolicies="contractPolicies"
                          :isRepeater="isRepeater"
                          @deleteSection="deleteSection"
                          @updateDocPages="updateDocPages"
                        />
                      </aCol>
                    </draggable>

                    <aCol
                      v-if="!previewDoc"
                      class="add-thing"
                      @click="openInsertColumn(page, index)"
                      :span="24"
                    >
                      <i class="fa-solid fa-table-columns mr-5"></i> ADD COLUNAS
                    </aCol>
                  </aRow>

                  <div v-if="!previewDoc" class="security-line">
                    <span
                      >MARGEM DE SEGURANÇA PDF
                      <font class="f8"> (não passar a linha) </font></span
                    >
                  </div>
                </div>
              </div>

              <div class="mb-20" :span="24"></div>
            </div>
          </draggable>

          <aRow v-if="!isRepeater">
            <aCol v-if="!previewDoc" class="add-page" :span="24">
              <a-button @click="addNewPage">ADD PAGINA</a-button>
            </aCol>
          </aRow>

          <div id="live-debug" style="display: none">
            <jsonViewer
              v-if="$store.state.userData.id == 1"
              class="mt-10 mb-20"
              :value="docStructure"
              :show-array-index="false"
              :expand-depth="4"
            ></jsonViewer>
          </div>

          <link
            href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swadp"
            rel="stylesheet"
          />

          <v-style class="none">
            .actions, .anticon-delete, .add-thing, .box-elements .type,
            .builder-area .prev-mode .box-elements .type, .builder-area
            .prev-mode .box-elements .add-thing, .builder-area .prev-mode
            .box-elements .empty-space { display: none; } #the-outer-document {
            line-height: 0 } p { margin-top: 0; margin-bottom: 1em; } table {
            border-collapse: collapse; } table { text-indent: initial;
            border-spacing: 2px; } .ql-align-center { text-align: center; }
            table td { color: #666; font-size: 13px; line-height: 14px; } b,
            strong { font-weight: 600 !important; } .ql-align-justify {
            text-align: justify; } .builder-container { font-size: 12px; } p {
            margin:0 0 10px 0 !important; padding: 0 !important } h1, h2, h3,
            h4, h5, h6 { margin: 0 !important; padding: 0 !important;
            font-weight: 500; line-height: 1.2, color: #000 } .element-wrapper {
            font-size: 12px; color: #666 } .page-content { padding: 0px
            !important } .ant-row { position: relative; height: auto;
            margin-right: 0; margin-left: 0; zoom: 1; display: block;
            box-sizing: border-box; } .ant-col-12 { display: block; box-sizing:
            border-box; width: 50%; } .ant-col-1, .ant-col-2, .ant-col-3,
            .ant-col-4, .ant-col-5, .ant-col-6, .ant-col-7, .ant-col-8,
            .ant-col-9, .ant-col-10, .ant-col-11, .ant-col-12, .ant-col-13,
            .ant-col-14, .ant-col-15, .ant-col-16, .ant-col-17, .ant-col-18,
            .ant-col-19, .ant-col-20, .ant-col-21, .ant-col-22, .ant-col-23,
            .ant-col-24 { flex: 0 0 auto; float: left; } .ant-col-8 { display:
            block; box-sizing: border-box; width: 33.33333333%; } .ant-col-6 {
            display: block; box-sizing: border-box; width: 25%; } .ql-size-small
            { font-size: 9px; color: #000; line-height: 2; } .ql-size-large {
            font-size: 16px; color: #000; line-height: 1; } .ql-size-huge {
            font-size: 20px; color: #000; line-height: 1 } .ql-align-right {
            text-align: right; } .ant-col-24 { display: block; box-sizing:
            border-box; width: 100%; } .ant-form { line-height: 1.5 }
          </v-style>
        </div>
      </aCol>
    </aRow>

    <a-modal
      class="doc-builder-modal"
      :footer="false"
      v-model="docBuilder.modals.openCollumns"
      @ok="docBuilder.modals.openCollumns = false"
      width="800px"
    >
      <template #title>
        <i class="fa-solid fa-table-columns mr-5"></i> INSERIR COLUNAS
      </template>
      <DocumentInsertColumnModal
        :page="docBuilder.selected.page"
        @addSelectedColumn="addSelectedColumn"
      />
    </a-modal>
  </section>
</template>

<script>
import draggable from "vuedraggable";

import DocumentColumnsSection from "../builder/sections/DocumentColumnsSection.vue";
import DocumentInsertColumnModal from "../modals/DocumentInsertColumnModal.vue";

export default {
  name: "DocPageBuilder",
  props: {
    undoStack: Array,
    redoStack: Array,
    tempDocument: Object,
    previewDoc: Boolean,
    loadingUpdateButton: Boolean,
    isRepeater: Boolean,
    docForm: Object,
    document: Object,
    docStructure: Array,
    contract: Object,
    contractPolicies: Array,
  },
  components: {
    draggable,
    DocumentColumnsSection,
    DocumentInsertColumnModal,
  },
  data() {
    return {
      docBuilder: {
        modals: {
          openCollumns: false,
        },
        selected: {
          index: undefined,
          page: undefined,
        },
      },
    };
  },
  created() {
    this.$on("reloadDocData", this.reloadDocData);
  },
  methods: {
    reloadDocData() {
      if (
        this.previewDoc &&
        this.isRepeater &&
        this.tempDocument.repeater_type === "credit-card-contract"
      ) {
        const creditCardRows =
          this.contract.meta.credit_card_rows !== undefined
            ? JSON.parse(this.contract.meta.credit_card_rows)
            : [];

        if (creditCardRows.length) {
          let arr = [];
          creditCardRows.forEach((card) => {
            arr.push({ ...this.docStructure[0], itemId: card.id });
          });

          this.docStructure = arr;
        }
      }

      console.log(
        "aaaaaa",
        this.previewDoc,
        this.isRepeater,
        this.tempDocument.repeater_type
      );

      if (
        this.previewDoc &&
        this.isRepeater &&
        this.tempDocument.repeater_type === "third-party-contract"
      ) {
        const thirdPartyRows =
          this.contract.meta.third_party_payment_rows !== undefined
            ? JSON.parse(this.contract.meta.third_party_payment_rows)
            : [];

        if (thirdPartyRows.length) {
          let arr = [];
          thirdPartyRows.forEach((tParty) => {
            arr.push({ ...this.docStructure[0], itemId: tParty.id });
          });

          this.docStructure = arr;
        }
      }
    },
    endDragSection(e) {
      console.log(e, this.docStructure);
      this.docStructure;
    },
    onClickCollapseAllPages() {
      this.docStructure.forEach((page) => {
        page.collapse = false;
      });
    },
    onClickExpandAllPages() {
      this.docStructure.forEach((page) => {
        page.collapse = true;
      });
    },
    addNewPage() {
      this.docStructure.push({
        collapse: true,
        css: {
          padding: "20px",
        },
        sections: [],
      });

      this.$emit("updateChangesDocStructure", this.docStructure);
    },
    deletePage(pIndex) {
      this.$emit("updateChangesDocStructure", this.docStructure);
      this.docStructure.splice(pIndex, 1);
    },
    collapsePage(index) {
      if (this.docStructure[index].collapse) {
        this.docStructure[index].collapse = false;
      } else {
        this.docStructure[index].collapse = true;
      }
    },
    addSelectedColumn(type) {
      this.docBuilder.modals.openCollumns = false;

      let updateDocPages = this.docStructure;

      if (type === "col1")
        updateDocPages[this.docBuilder.selected.index].sections.push({
          type,
          columns: [
            {
              elements: [],
            },
          ],
        });

      if (type === "col2")
        updateDocPages[this.docBuilder.selected.index].sections.push({
          type,
          columns: [
            {
              elements: [],
            },
            {
              elements: [],
            },
          ],
        });

      if (type === "col3")
        updateDocPages[this.docBuilder.selected.index].sections.push({
          type,
          columns: [
            {
              elements: [],
            },
            {
              elements: [],
            },
            {
              elements: [],
            },
          ],
        });

      if (type === "col4")
        updateDocPages[this.docBuilder.selected.index].sections.push({
          type,
          columns: [
            {
              elements: [],
            },
            {
              elements: [],
            },
            {
              elements: [],
            },
            {
              elements: [],
            },
          ],
        });

      this.docStructure = updateDocPages;
      this.$emit("updateChangesDocStructure", this.docStructure);
    },
    openInsertColumn(page, index) {
      this.docBuilder.selected.page = page;
      this.docBuilder.selected.index = index;
      this.docBuilder.modals.openCollumns = true;
    },
    deleteSection(pIndex, sIndex) {
      this.docStructure[pIndex].sections.splice(sIndex, 1);
      this.$emit("updateChangesDocStructure", this.docStructure);
    },
    updateSectionElements(pIndex, sIndex, newElements) {
      this.docStructure[pIndex].sections[sIndex].elements = newElements;
      this.$emit("updateChangesDocStructure", this.docStructure);
    },
    updateDocPages(pageIndex, sectionIndex, newSection) {
      this.docStructure[pageIndex].sections[sectionIndex] = newSection;
      this.$emit("updateChangesDocStructure", this.docStructure);
    },
    updateChangesElements(pIndex, sIndex, eindex, content) {
      this.docStructure[pIndex].sections[sIndex].elements[eindex].content =
        content;
      this.$emit("updateChangesDocStructure", this.docStructure);
    },
    previewPageFirstContent(page) {
      let theContent = "Nenhum elemento inserido";
      if (page.sections.length > 0) {
        if (page.sections[0].elements != undefined)
          if (page.sections[0].elements.length > 0)
            theContent = page.sections[0].elements[0].content;

        if (page.sections[0].columns != undefined)
          if (page.sections[0].columns[0].elements.length > 0)
            theContent = page.sections[0].columns[0].elements[0].content;
      }

      theContent = theContent.replace(/<\s*br\/*>/gi, "\n");
      theContent = theContent.replace(
        /<\s*a.*href="(.*?)".*>(.*?)<\/a>/gi,
        " $2 (Link->$1) "
      );
      theContent = theContent.replace(/<\s*\/*.+?>/gi, "\n");
      theContent = theContent.replace(/ {2,}/gi, " ");
      theContent = theContent.replace(/\n+\s*/gi, "\n\n");

      return theContent;
    },
    clonePage(pIndex) {
      let pageToClone = this.docStructure[pIndex];
      pageToClone = JSON.stringify(pageToClone);
      this.docStructure.splice(pIndex, 0, JSON.parse(pageToClone));
      this.$emit("updateChangesDocStructure", this.docStructure);
    },
  },
};
</script>

<style lang="sass">
.builder-container
  font-size: 12px
  .content p
    margin-bottom: 10px !important
  h1, h2, h3, h4, h5, h6
    margin: 0 !important
    padding: 0 !important
    line-height: 1.2
</style>

<style lang="sass" scoped>
.builder-container
  padding: 30px 30px 30px
  .expandable-pages
    position: absolute
    right: 0
  .collapse-all
    cursor: pointer
    position: relative
    top: -23px
    margin-bottom: 2px
    z-index: 10
    width: 90px
    font-size: 9px
    text-align: center
    float: right
    background: #434a53
    font-weight: 600
    padding: 5px
    color: #FFF
    opacity: 0.2
    transition: .3s
  .collapse-all:hover
    opacity: 1
  .builder-area
    margin: 0 auto
    width: 802px
    position: relative
    .add-page
      position: relative
      z-index: 10
      text-align: center
      padding: 10px
      transition: .3s
    .page
      position: relative
      .page-header
        position: relative
        z-index: 1
        background: #434a53
        font-weight: 500
        padding: 10px
        color: #FFF
        width: 802px
        //width: 990px
        .title
          font-size: 13px
          .dotted-phrase
            width: 300px
            display: inline-block
            opacity: 0.2
            font-size: 10px
            position: relative
            top: 3px
            left: 70px
        .collapse-arrow, .css, .delete
          cursor: pointer
          font-size: 13px
      .page-content
        position: relative
        overflow: hidden
        width: 802px
        height: 1440px
        background: #FFF
        box-shadow: 0 0 90px #cbcbcb

        .security-line
          position: absolute
          border-top: 2px dashed #999
          left: 0
          right: 0
          bottom: 0
          padding: 3px 20px
          font-size: 10px
          background: #FFF
          opacity: 0.8
          z-index: 1
          color: #999
          font-weight: 500
          padding-bottom: 100px
        .add-thing
          text-align: center
          padding: 8px 14px
          border: 2px solid #ddd
          cursor: pointer
          color: #aaa
          margin: 5px 0
          font-weight: 500
</style>
