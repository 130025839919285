<template>
  <section class="document-builder pd-0">
    <aForm
      :form="docForm"
      @submit="handleSubmit"
      @onFieldsChange="updateTempDocument"
    >
      <DocPageTopBar
        v-show="tempDocument.id"
        :docForm="docForm"
        :document="document"
        :previewDoc="previewDoc"
        :undoStack="undoStack"
        :redoStack="redoStack"
        :tempDocument="tempDocument"
        :loadingUpdateButton="loadingUpdateButton"
        :contract="contract.details"
        :docStructure="document.structure"
        @undo="undoChanges"
        @redo="redoChanges"
        @onClickPrevDoc="onClickPrevDoc"
        @onCloseDocumentInfosDrawer="onCloseDocumentInfosDrawer"
        @onChangeIsRepeater="onChangeIsRepeater"
      />

      <DocPageBuilder
        ref="DocPageBuilder"
        v-if="tempDocument.id"
        :docForm="docForm"
        :document="document"
        :previewDoc="previewDoc"
        :undoStack="undoStack"
        :redoStack="redoStack"
        :tempDocument="tempDocument"
        :isRepeater="tempDocument.is_repeater"
        :loadingUpdateButton="loadingUpdateButton"
        :docStructure="document.structure"
        :contract="contract.details"
        :contractPolicies="policy.list"
        @updateChangesDocStructure="updateChangesDocStructure"
      />
    </aForm>
  </section>
</template>

<script>
import documentMixins from "@/components/documents/mixins/documentMixins";
import policyMixins from "@/mixins/policies/policyMixins";
import contractMixins from "@/components/contracts/mixins/contractMixins";

import DocPageTopBar from "@/components/documents/sections/DocPageTopBar.vue";
import DocPageBuilder from "@/components/documents/sections/DocPageBuilder.vue";

export default {
  components: {
    DocPageTopBar,
    DocPageBuilder,
  },
  mixins: [documentMixins, contractMixins, policyMixins],
  data() {
    return {
      docForm: this.$form.createForm(this, {
        onValuesChange: this.updateTempDocument,
      }),
      undoStack: [],
      redoStack: [],
      docStructureBackup: [],
      previewDoc: false,
      loadingUpdateButton: false,
    };
  },
  async mounted() {
    await this.getDocumentById(this.$route.params.id).then(({ data }) => {
      this.document.details = data;

      this.docForm.setFieldsValue({
        name: data.name,
        contract_id: data.contract_id != 0 ? data.contract_id : undefined,
      });

      this.tempDocument = {
        ...data.raw,
      };

      this.tempDocument.status = this.tempDocument.status == 1 ? true : false;
      this.tempDocument.is_general =
        this.tempDocument.is_general == 1 ? true : false;

      this.tempDocument.is_repeater =
        this.tempDocument.is_repeater == 1 ? true : false;

      this.tempDocument.contract_id =
        this.tempDocument.contract_id != 0
          ? this.tempDocument.contract_id
          : undefined;

      delete this.tempDocument.structure;
      delete this.tempDocument.html;

      this.document.structure = data.html ? data.html : [];
      this.docStructureBackup = data.html ? data.html : [];

      if (data.contract_id != 0)
        this.getContractById(data.contract_id).then(() => {
          this.policy.filters.module = "contract";
          this.policy.filters.id = data.contract_id;
          this.policy.filters.showPolicy = 1;
          this.getPolicies();
        });
    });
  },
  methods: {
    onChangeIsRepeater() {
      if (this.tempDocument.is_repeater) {
        this.document.structure = [];
        this.document.structure.push({
          collapse: true,
          css: {
            padding: "20px",
          },
          sections: [],
        });
      } else {
        this.document.structure = this.docStructureBackup;
      }
    },
    onCloseDocumentInfosDrawer() {
      if (this.tempDocument.contract_id)
        this.getContractById(this.tempDocument.contract_id).then(() => {
          this.policy.filters.module = "contract";
          this.policy.filters.id = this.tempDocument.contract_id;
          this.policy.filters.showPolicy = 1;
          this.getPolicies();
        });
    },
    updateChangesDocStructure(docStructure) {
      this.undoStack.push(JSON.stringify(docStructure));
      this.redoStack = [];
    },
    undoChanges() {
      if (this.undoStack.length > 1) {
        const lastValue = this.undoStack.pop();
        this.redoStack.push(lastValue);
        this.document.structure = JSON.parse(
          this.undoStack[this.undoStack.length - 1]
        );
      }
    },
    redoChanges() {
      if (this.redoStack.length > 0) {
        const nextValue = this.redoStack.pop();
        this.undoStack.push(nextValue);
        this.document.structure = JSON.parse(nextValue);
      }
    },
    updateTempDocument(props, fields) {
      let field = Object.keys(fields)[0],
        value = Object.values(fields)[0];

      this.tempDocument[field] = value;

      if (Array.isArray(value))
        this.tempDocument[field] = JSON.stringify(value);
    },
    handleSubmit(e) {
      e.preventDefault();
      this.docForm.validateFields((err, values) => {
        values;
        if (!err) {
          this.loadingUpdateButton = true;

          this.tempDocument.html = this.document.structure;
          this.tempDocument.modified_by = {
            name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
            id: this.$store.state.userData.id,
          };

          this.$http
            .post("/document/update", this.tempDocument)
            .then(({ data }) => {
              this.$message.success(data.message);
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
            })
            .finally(() => {
              this.loadingUpdateButton = false;
            });
        }
      });
    },
    onClickPrevDoc(value) {
      this.previewDoc = value;
      setTimeout(() => {
        this.$refs.DocPageBuilder.$emit("reloadDocData");
      }, 100);
    },
  },
};
</script>

<style lang="sass" scoped>
.document-builder
  padding-top: 70px !important
  position: relative
</style>

<style lang="sass">
.doc-builder-modal
  .ant-modal-close-x i, .ant-modal-title
    color: #FFF
  .ant-modal-header
    background: #be4178
// HIDE ELEMENTS FOR PREV MODE
.builder-area
  .prev-mode
    height: 1200px !important
    .outer-box
      .add-thing, .empty-space, .type
        display: none !important
    .content
      background: #FFF
    .mb-10
      margin-bottom: 0 !important
    .box-elements
      border: 0
      padding: 0
      .type, .add-thing, .empty-space
        display: none !important
</style>
